
export default [
    {
        id: 583478963,
        name: "Pikachu",
        url: "https://metro.co.uk/wp-content/uploads/2015/05/pokemon_crying.gif?quality=90&strip=all&zoom=1&resize=500%2C284",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "0.05",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Ethereum',
        rarity: 'SP Legend'
    },
    {
        id: 647821598,
        name: "What?",
        url: "https://media4.popsugar-assets.com/files/2013/11/07/832/n/1922398/eb7a69a76543358d_28.gif",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "0.01",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Avalanche',
        rarity: 'Super Rare'
    },
    {
        id: 158975346,
        name: "Why?",
        url: "https://acegif.com/wp-content/uploads/gif-shaking-head-38.gif",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "0.10",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Fantom',
        rarity: 'Common'
    },
    {
        id: 365471658,
        name: "Starlord",
        url: "https://i.pinimg.com/originals/68/a0/9e/68a09e774e98242871c2db0f99307420.gif",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "0.17",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Moonbeam',
        rarity: 'Legend'
    },
    {
        id: 568752147,
        name: "Thor",
        url: "https://i.pinimg.com/originals/73/d3/a1/73d3a14d212314ab1f7268b71d639c15.gif",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "0.20",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Polygon',
        rarity: 'Rare'
    },
    {
        id: 741258963,
        name: "It's so beautiful",
        url: "https://www.omnisend.com/blog/wp-content/uploads/2016/09/funny-gifs-9.gif",
        message: "",
        timestamp: "12/21/2021, 4:33:21 PM",
        addressFrom: "0xCF8e569A97C423952DdFf902375C7C76549A6A90",
        amount: "3",
        addressTo: "0x8aa395Ab97837576aF9cd6946C79024ef1acfdbE",
        chain: 'Moonbeam',
        rarity: 'Epic'
    },
];
